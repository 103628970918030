.fortune-container {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  flex-direction: column;
  font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

.fortune-workarea {
  width: 100%;
}

.fortune-popover-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1003;
  /* should below .fortune-context-menu */
  height: 100%;
  width: 100%;
}

.fortune-modal-container {
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sheet-background {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  background-color: white;
}

html::-webkit-scrollbar-button {
  display: none;
}

.fortune-sheet-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.fortune-col-body {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.fortune-sheet-area {
  flex: 1;
  position: relative;
}

.fortune-sheet-canvas-placeholder,
.fortune-sheet-canvas {
  width: 100%;
  height: 100%;
  display: block;
}

.fortune-sheet-canvas {
  position: absolute;
}
.fortune-sheet-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  outline-style: none;
}

.fortune-cell-area {
  border-collapse: collapse;
  position: relative;
  overflow: hidden;
  outline-style: none;
  cursor: default;
}

.fortune-row-body {
  display: flex;
  flex-direction: row;
}

.fortune-row-header {
  position: relative;
  flex-shrink: 0;
  outline-style: none;
  color: #5e5e5e;
  overflow: hidden;
  padding: 0;
  margin-top: -2px;
  padding-top: 2px;
  cursor: default;
  width: 45px;
}

.fortune-row-header-hover {
  position: absolute;
  z-index: 11;
  border: 0 none;
  right: 0;
  width: 100%;
  margin-top: 2px;
  display: none;
  background-color: rgba(194, 194, 194, 0.4);
}

.fortune-row-header-selected {
  position: absolute;
  z-index: 10;
  border-right: 1px solid #0188fb;
  right: 0;
  width: 100%;
  margin-top: 2px;
  display: none;
  transition: all 0.1s;
  background-color: rgba(76, 76, 76, 0.1);
}

.fortune-col-header-wrap {
  display: flex;
  flex-direction: row;
}

.fortune-col-header {
  color: #5e5e5e;
  overflow: hidden;
  padding: 0;
  cursor: default;
  flex: 1;
  height: 19px;
  outline-style: none;
  position: relative;
}

.fortune-col-header-hover {
  color: #5e5e5e;
  cursor: default;
  position: absolute;
  z-index: 11;
  border: 0 none;
  bottom: 0;
  height: 100%;
  margin-left: 0px;
  display: none;
  background-color: rgba(194, 194, 194, 0.4);
}

.fortune-col-header-hover .header-arrow {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translate(0%, -44%);
}

.fortune-col-header-selected {
  color: #5e5e5e;
  cursor: default;
  position: absolute;
  z-index: 10;
  border-bottom: 1px solid #0188fb;
  bottom: 0;
  height: 100%;
  margin-left: 0px;
  display: none;
  transition: all 0.1s;
  background-color: rgba(76, 76, 76, 0.1);
}

.fortune-left-top {
  width: 44.5px;
  height: 18.5px;
  border: solid 0 #dfdfdf;
  position: relative;
  padding-top: 0;
  border-width: 0 1px 1px 0;
  padding-left: 0;
  cursor: pointer;
  background-color: white;
}

.fortune-add-row-button {
  padding: 1px 20px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  margin: 0 8px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  cursor: pointer;
  color: rgb(38, 42, 51);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(200, 200, 200);
}

.luckysheet-cell-selected-focus {
  position: absolute;
  pointer-events: none;
  z-index: 14;
  /*border:1px solid #fff;*/
  margin: 0px 0 0 0px;
  background: rgba(0, 80, 208, 0.15);
  display: none;
  /*transition: all 0.1s;*/
}

.fortune-selection-copy {
  position: absolute;
  pointer-events: none;
  z-index: 18;
  border: none;
  margin: 0px 0 0 0px;
}

.fortune-selection-copy .fortune-copy {
  position: absolute;
  z-index: 18;
  background-color: transparent;
}

.fortune-selection-copy-hc {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px dashed #12a5ff;
  z-index: 8;
}

.fortune-selection-highlight {
  position: absolute;
  /*pointer-events: none;*/
  z-index: 14;
  border: none;
  margin: 0px 0 0 0px;
  /* display: none; */
}

.fortune-cell-selected-extend {
  position: absolute;
  pointer-events: none;
  z-index: 16;
  border: 1px dashed #0188fb;
  margin: -1px 0 0 -1px;
  display: none;
  /*transition: all 0.1s;*/
}

.fortune-cell-selected-move {
  cursor: move;
  position: absolute;
  /* pointer-events: none; */
  z-index: 16;
  border: 2px solid #0188fb;
  margin: -1px 0 0 -1px;
  display: none;
  /*transition: all 0.1s;*/
}

.luckysheet-cell-selected {
  position: absolute;
  pointer-events: none;
  z-index: 15;
  border: 1px solid #0188fb;
  margin: -1px 0 0 -1px;
  background: rgba(1, 136, 251, 0.15);
  display: none;
  box-sizing: content-box;
}

.luckysheet-cs-inner-border {
  pointer-events: none;
  border: 1px solid #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.luckysheet-cs-fillhandle {
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: -5px;
  cursor: crosshair;
  background-color: #0188fb;
  border: solid 1px #fff;
  z-index: 16;
  pointer-events: auto;
  right: -5px;
}

.luckysheet-cs-draghandle {
  position: absolute;
  cursor: move;
  background-color: #fff;
  opacity: 0.01;
  z-index: 15;
  pointer-events: auto;
  border: 2px solid #fff;
}

.luckysheet-cs-draghandle-top {
  top: -4px;
  left: -2px;
  right: -2px;
  height: 2px;
}

.luckysheet-cs-draghandle-bottom {
  right: 0;
  left: -2px;
  bottom: -4px;
  height: 2px;
}

.luckysheet-cs-draghandle-left {
  top: 0;
  left: -4px;
  bottom: 0;
  width: 2px;
}

.luckysheet-cs-draghandle-right {
  top: 0;
  right: -4px;
  bottom: 0;
  width: 2px;
}

.luckysheet-cs-touchhandle {
  display: none;
  position: absolute;
  width: 16px;
  height: 16px;
  padding: 5px;
  z-index: 100;
  pointer-events: auto;
  touch-action: auto;
}

.luckysheet-cs-touchhandle:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
}

.luckysheet-cs-touchhandle-lt {
  left: -13px;
  top: -13px;
}

.luckysheet-cs-touchhandle-lb {
  left: -13px;
  bottom: -13px;
}

.luckysheet-cs-touchhandle-rt {
  right: -13px;
  top: -13px;
}

.luckysheet-cs-touchhandle-rb {
  right: -13px;
  bottom: -13px;
}

.luckysheet-cs-touchhandle .luckysheet-cs-touchhandle-btn {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 8px;
  top: 8px;
  background-color: #018ffb;
  background-position: center;
  box-sizing: border-box;
  border-radius: 50%;
  z-index: 11;
}

.luckysheet-input-box {
  position: absolute;
  font: normal normal 400 13px arial, sans, sans-serif;
  z-index: 15;
  display: flex;
  flex-direction: column;
}

.luckysheet-input-box-inner {
  font: normal normal 400 13px arial, sans, sans-serif;
  text-align: left;
  max-height: 9900px;
  max-width: 9900px;
  border: 1px #5292f7 solid;
  padding: 0 2px;
  margin: 0;
  resize: none;
  overflow: hidden;
  white-space: pre-wrap;
  outline: none;
  -webkit-box-shadow: 0 2px 5px rgb(0 0 0 / 40%);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 5px rgb(0 0 0 / 40%);
  word-wrap: break-word;
  background-color: rgb(255, 255, 255);
  font-size: 13px;
  right: auto;
  overflow-y: auto;
  box-sizing: border-box;
}

.luckysheet-cell-input {
  width: 100%;
  margin: 0;
  outline: none;
  cursor: text;
  white-space: pre-wrap;
}

.luckysheet-formula-text-color {
  color: black;
}

.luckysheet-formula-text-string {
  color: forestgreen;
}

.luckysheet-cell-flow {
  margin: 0;
  padding: 0;
  border: none 0;
  position: relative;
  touch-action: manipulation;
}

.luckysheet-cell-flow-clip {
  border-collapse: collapse;
  cursor: default;
  width: 5000000px;
  touch-action: manipulation;
  overflow: hidden;
}

.luckysheet-cell-flow-col {
  margin: 0;
  padding: 0;
  border: none 0;
  position: relative;
  touch-action: manipulation;
  overflow: hidden;
  float: left;
  direction: ltr;
}

.luckysheet-cell-sheettable {
  position: relative;
  /*background-color: #fff;*/
  text-align: left;
  font-size: 11pt;
  color: #000000;
  text-decoration: none;
}

.luckysheet-bottom-controll-row {
  position: absolute;
  height: 30px;
  /*width: 400px;*/
  /* background: #000; */
  bottom: 38px;
  left: 0px;
  z-index: 1000;
}

#luckysheet-bottom-add-row {
  padding: 5px 20px;
  margin-right: 5px;
  margin-top: -2px;
}

#luckysheet-bottom-add-row-input {
  width: 40px;
  min-width: 40px;
}

#luckysheet-bottom-return-top {
  padding: 5px 6px;
  margin-left: 10px;
  margin-top: -2px;
}

.luckysheet-cell-flow-column {
  position: absolute;
  height: inherit;
  width: inherit;
  top: 0;
  left: 0;
  z-index: 1;
  touch-action: manipulation;
}

.luckysheet-cell-flow-column-line {
  position: absolute;
  border-right: 1px solid #d4d4d4;
  height: inherit;
}

.luckysheet-cell-flow-row {
  text-align: left;
  position: absolute;
  height: inherit;
  width: inherit;
  top: 0;
  left: 0;
  z-index: 1;
  touch-action: manipulation;
}

.luckysheet-cell-flow-row-line {
  position: absolute;
  border-bottom: 1px solid #d4d4d4;
  width: inherit;
}

.fortune-cols-change-size,
.fortune-rows-change-size,
.fortune-change-size-line {
  /*display: none;*/
  position: absolute;
  z-index: 12;
}

.fortune-cols-change-size {
  width: 5px;
  height: 100%;
  background: #0188fb;
  cursor: ew-resize;
  opacity: 0;
}

.fortune-rows-change-size {
  width: 100%;
  height: 5px;
  background: #0188fb;
  cursor: ns-resize;
  opacity: 0;
}

.fortune-change-size-line {
  border-color: #0188fb;
  border-style: solid;
  z-index: 15;
  cursor: ew-resize;
}

/*批注聚焦框 移动 改变大小*/
.luckysheet-postil-dialog-move {
  position: absolute;
  margin: 0px;
  padding: 0px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.luckysheet-postil-dialog-move .luckysheet-postil-dialog-move-item {
  /*background: #000;*/
  position: absolute;
  pointer-events: all;
  cursor: move;
}

.luckysheet-postil-dialog-move .luckysheet-postil-dialog-move-item-t {
  width: 100%;
  height: 3px;
  /* border-bottom: 1px solid #000; */
  left: 0;
  top: -4px;
}

.luckysheet-postil-dialog-move .luckysheet-postil-dialog-move-item-r {
  width: 3px;
  height: 100%;
  /* border-left: 1px solid #000; */
  right: -4px;
  top: 0;
}

.luckysheet-postil-dialog-move .luckysheet-postil-dialog-move-item-b {
  width: 100%;
  height: 3px;
  /* border-top: 1px solid #000; */
  left: 0;
  bottom: -4px;
}

.luckysheet-postil-dialog-move .luckysheet-postil-dialog-move-item-l {
  width: 3px;
  height: 100%;
  /* border-right: 1px solid #000; */
  left: -4px;
  top: 0;
}

.luckysheet-postil-show-active .luckysheet-postil-dialog-move .luckysheet-postil-dialog-move-item {
  border-color: #0188fb;
}

.luckysheet-postil-dialog-resize {
  position: absolute;
  margin: 0px;
  padding: 0px;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  pointer-events: none;
}

.luckysheet-postil-dialog-resize .luckysheet-postil-dialog-resize-item {
  position: absolute;
  height: 6px;
  width: 6px;
  border: 1px solid #0188fb;
  pointer-events: all;
}

.luckysheet-postil-dialog-resize .luckysheet-postil-dialog-resize-item-lt {
  left: -6px;
  top: -6px;
  cursor: nw-resize;
}

.luckysheet-postil-dialog-resize .luckysheet-postil-dialog-resize-item-mt {
  left: 50%;
  top: -6px;
  margin-left: -4px;
  cursor: n-resize;
}

.luckysheet-postil-dialog-resize .luckysheet-postil-dialog-resize-item-lm {
  top: 50%;
  left: -6px;
  margin-top: -4px;
  cursor: w-resize;
}

.luckysheet-postil-dialog-resize .luckysheet-postil-dialog-resize-item-rm {
  top: 50%;
  right: -6px;
  margin-top: -4px;
  cursor: e-resize;
}

.luckysheet-postil-dialog-resize .luckysheet-postil-dialog-resize-item-rt {
  right: -6px;
  top: -6px;
  cursor: ne-resize;
}

.luckysheet-postil-dialog-resize .luckysheet-postil-dialog-resize-item-lb {
  left: -6px;
  bottom: -6px;
  cursor: sw-resize;
}

.luckysheet-postil-dialog-resize .luckysheet-postil-dialog-resize-item-mb {
  left: 50%;
  bottom: -6px;
  margin-left: -4px;
  cursor: s-resize;
}

.luckysheet-postil-dialog-resize .luckysheet-postil-dialog-resize-item-rb {
  right: -6px;
  bottom: -6px;
  cursor: se-resize;
}

.fortune-selection-copy-top {
  left: 0;
  right: 0;
  height: 2px;
  top: 0;
  background-position: bottom;
  /* background-image: url("EwaAntH.gif"); */
}

.fortune-selection-copy-right {
  top: 0;
  bottom: 0;
  width: 2px;
  right: 0;
  /* background-image: url("EwaAntV.gif"); */
}

.fortune-selection-copy-bottom {
  left: 0;
  right: 0;
  height: 2px;
  bottom: 0;
  /* background-image: url("EwaAntH.gif"); */
}

.fortune-selection-copy-left {
  top: 0;
  bottom: 0;
  width: 2px;
  left: 0;
  background-position: right;
  /* background-image: url("EwaAntV.gif"); */
}

.fortune-selection-copy-hc {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px dashed #12a5ff;
  z-index: 8;
}

.luckysheet-modal-dialog-resize {
  position: absolute;
  border: 2px solid #0188fb;
  margin: 0px;
  padding: 0px;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  pointer-events: none;
}

.luckysheet-modal-dialog-resize-item {
  position: absolute;
  height: 6px;
  width: 6px;
  background: #ffffff;
  border: 2px solid #0188fb;
  pointer-events: all;
  border-radius: 6px;
}

.luckysheet-modal-dialog-resize-item-lt {
  left: -6px;
  top: -6px;
  cursor: se-resize;
}

.luckysheet-modal-dialog-resize-item-mt {
  left: 50%;
  top: -6px;
  margin-left: -4px;
  cursor: s-resize;
}

.luckysheet-modal-dialog-resize-item-rt {
  right: -6px;
  top: -6px;
  cursor: ne-resize;
}

.luckysheet-modal-dialog-resize-item-lm {
  top: 50%;
  left: -6px;
  margin-top: -4px;
  cursor: w-resize;
}

.luckysheet-modal-dialog-resize-item-rm {
  top: 50%;
  right: -6px;
  margin-top: -4px;
  cursor: w-resize;
}

.luckysheet-modal-dialog-resize-item-lb {
  left: -6px;
  bottom: -6px;
  cursor: ne-resize;
}

.luckysheet-modal-dialog-resize-item-mb {
  left: 50%;
  bottom: -6px;
  margin-left: -4px;
  cursor: s-resize;
}

.luckysheet-modal-dialog-resize-item-rb {
  right: -6px;
  bottom: -6px;
  cursor: se-resize;
}

.fortune-formula-functionrange-highlight .fortune-copy {
  background-image: none;
  background: #0188fb;
  position: absolute;
  z-index: 18;
  cursor: move;
  opacity: 0.9;
  box-sizing: content-box;
  /*border: 1px solid #fff;*/
}

.fortune-formula-functionrange-highlight .fortune-selection-copy-top {
  top: -2px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.fortune-formula-functionrange-highlight .fortune-selection-copy-right {
  right: -2px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.fortune-formula-functionrange-highlight .fortune-selection-copy-bottom {
  bottom: -2px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.fortune-formula-functionrange-highlight .fortune-selection-copy-left {
  left: -2px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.fortune-formula-functionrange-highlight .fortune-selection-copy-hc {
  border: 2px solid #5e5e5e;
  opacity: 0.03;
  z-index: initial;
}

.fortune-selection-highlight-lt {
  left: -3px;
  top: -3px;
  cursor: se-resize;
}

.fortune-selection-highlight-rt {
  right: -3px;
  top: -3px;
  cursor: ne-resize;
}

.fortune-selection-highlight-lb {
  left: -3px;
  bottom: -3px;
  cursor: ne-resize;
}

.fortune-selection-highlight-rb {
  right: -3px;
  bottom: -3px;
  cursor: se-resize;
}

.fortune-formula-functionrange-highlight .luckysheet-highlight {
  position: absolute;
  z-index: 19;
  border: 1px solid #fff;
  background: #0188fb;
  width: 6px;
  height: 6px;
}

.fortune-presence-username {
  position: absolute;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  left: -2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: content-box;
  color: #fff;
}

.fortune-presence-selection {
  position: absolute;
  border-style: solid;
  border-width: 1;
  opacity: 0.7;
}

.luckysheet-filter-options {
  color: #897BFF;
  cursor: pointer;
  position: absolute;
  z-index: 200;
  border: 1px solid #897BFF;
  border-radius: 3px;
  top: 3px;
  margin-left: 0px;
  display: 'block';
  padding: 0px 4px;
  font-size: 12px;
  height: 15px;
  background: #fff;
  /* transition: all 0.1s; */
}

.luckysheet-filter-options:hover {
  color: #fff;
  border: 1px solid #fff;
  background: #897BFF;
}

.luckysheet-filter-options-active {
  color: #fff;
  border: 1px solid #897BFF;
  padding: 0px 1px;
  background: #897BFF;
}

.caret {
  margin-top: 6px;
  width: 0;
  height: 0;
  display: inline-block;
  border: 4px solid transparent;
}

.caret.down {
  border-top-color: #897BFF;
}

.luckysheet-filter-options:hover .caret.down {
  border-top-color: #ffffff;
}

.luckysheet-filter-selected {
  background: #ffffff00;
}

#luckysheet-dataVerification-showHintBox {
  display: none;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
  white-space: nowrap;
  font-size: 12px;
}

#luckysheet-dataVerification-dropdown-btn {
  display: none;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  position: absolute;
  z-index: 10;
  overflow: hidden;
}
.luckysheet-formula-search-c {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #535353;
  font-size: 12px;
  background: #fff;
  z-index: 1003;
  width: 300px;
}

.luckysheet-formula-search-c .luckysheet-formula-search-item {
  background: #fff;
  padding: 5px 10px;
  cursor: pointer;
}

.luckysheet-formula-search-c
  .luckysheet-formula-search-item
  .luckysheet-formula-search-detail {
  display: none;
  color: #444;
}

.luckysheet-formula-search-c
  .luckysheet-formula-search-item
  .luckysheet-formula-search-func {
  color: #222;
  font-size: 14px;
}

.luckysheet-formula-search-c .luckysheet-formula-search-item-active {
  display: block;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  background: #f5f5f5;
}

.luckysheet-formula-search-c
  .luckysheet-formula-search-item-active
  .luckysheet-formula-search-detail {
  display: block;
}

.luckysheet-formula-help-c {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #535353;
  font-size: 12px;
  background: #fff;
  z-index: 1003;
  width: 300px;
}

.luckysheet-formula-help-c .luckysheet-formula-help-content {
  max-height: 300px;
  overflow-y: scroll;
}

.luckysheet-formula-help-content-example {
  margin-top: 5px;
}

.luckysheet-formula-help-title {
  display: block;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  background: #f5f5f5;
  padding: 2px 10px;
  font-size: 14px;
}

.luckysheet-formula-help-title-formula {
  width: 250px;
  word-break: break-word;
}

.luckysheet-arguments-help-section {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #222;
}

.luckysheet-arguments-help-section-title {
  padding: 1px 10px;
  color: #666;
}

.luckysheet-arguments-help-parameter-content {
  padding: 1px 10px;
  display: inline-block;
  word-wrap: break-word;
}

.luckysheet-arguments-help-formula {
  padding: 1px 10px;
  font-size: 14px;
}

.luckysheet-arguments-help-parameter-active {
  background-color: #fff9b2;
}

.luckysheet-formula-help-collapse {
  position: absolute;
  top: 0px;
  right: 25px;
  font-size: 16px;
  cursor: pointer;
  color: #bbb;
}

.luckysheet-formula-help-close {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
  color: #bbb;
}

.luckysheet-formula-help-close:hover,
.luckysheet-formula-help-collapse:hover {
  color: #555;
}

.luckysheet-scrollbar-ltr {
  position: absolute;
  overflow: hidden;
  z-index: 1003;
}

.luckysheet-scrollbar-ltr div {
  height: 1px;
  width: 1px;
}

.luckysheet-scrollbar-x {
  bottom: 0px;
  overflow-x: scroll;
}

.luckysheet-scrollbar-y {
  right: 0px;
  top: 0px;
  overflow-y: scroll;
}

.luckysheet-scrollbar-ltr::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
}

.luckysheet-scrollbar-ltr::-webkit-scrollbar-track {
  background-color: transparent;
}

.luckysheet-scrollbar-ltr::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}

.luckysheet-scrollbar-ltr::-webkit-scrollbar-button {
  display: none;
}

.fortune-dialog {
  max-width: 90%;
  max-height: 90%;
  overflow: scroll;
  border-radius: 6px;
  background: white;
  box-shadow: rgb(0 0 0 / 10%) 5px 5px 30px;
  box-sizing: border-box;
  overflow: auto;
}

.fortune-dialog-box-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fortune-dialog-box-content {
  padding: 0px 25px;
}

.fortune-dialog-box-button-container {
  padding-top: 10px;
  padding-bottom: 20px;
}

.fortune-message-box-button {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  margin: 0 8px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  cursor: pointer;
}

.fortune-message-box-button.button-default {
  color: rgb(38, 42, 51);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(235, 235, 235);
}

.fortune-message-box-button.button-primary {
  color: white;
  background-color: #0188FB;
}

.fortune-modal-dialog-header {
  outline: 0;
  display: flex;
  justify-content: flex-end;
}

.fortune-modal-dialog-icon-close {
  color: #d4d4d4;
  opacity: 0.3;
}

.fortune-modal-dialog-icon-close:hover {
  opacity: 0.7;
}
/*查找替换弹出框样式*/
#fortunesheet-search-replace {
  position: absolute;
  padding: 30px 42px;
  z-index: 1002;
}

#fortunesheet-search-replace .icon-close {
  position: absolute;
  right: 3px;
  top: 3px;
}

#fortunesheet-search-replace .tabBox {
  margin-top: 10px;
  font-size: 0;
}

#fortunesheet-search-replace .tabBox span {
  display: inline-block;
  text-align: center;
  width: 100px;
  border: 1px solid rgb(235, 235, 235);
  font-size: 14px;
  line-height: 2;
}

#fortunesheet-search-replace .tabBox span.on {
  background-color: #8C89FE;
  border-color: #726EFE;
  color: #fff;
}

#fortunesheet-search-replace .ctBox {
  padding: 5px 10px;
  border: 1px solid rgb(235, 235, 235);
  font-size: 14px;
  min-width: 500px;
}

#fortunesheet-search-replace .ctBox .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#fortunesheet-search-replace .inputBox {
  height: 90px;
  position: relative;
}

#fortunesheet-search-replace .inputBox .textboxs {
  height: 30px;
  line-height: 30px;
}

#fortunesheet-search-replace .checkboxs {
  height: 90px;
}

#fortunesheet-search-replace .checkboxs div {
  height: 30px;
  line-height: 30px;
}

#fortunesheet-search-replace .checkboxs input[type="checkbox"] {
  float: left;
  margin-top: 9px;
}

#fortunesheet-search-replace .btnBox {
  margin-top: 10px;
}

#fortunesheet-search-replace .btnBox .button-default {
  margin-right: 8px;
  margin-left: 0px;
}

#fortunesheet-search-replace .close-button {
  margin-left: 0px;
  margin-top: 10px;
}

#fortunesheet-search-replace #searchAllbox {
  height: 210px;
  border: 1px solid #d4d4d4;
  margin-top: 10px;
  overflow-y: auto;
  position: relative;
}

#fortunesheet-search-replace #searchAllbox .boxTitle {
  width: 100%;
  height: 30px;
  line-height: 29px;
  padding: 0 5px;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  box-sizing: border-box;
  position: sticky;
  left: 0;
  top: 0;
}

#fortunesheet-search-replace #searchAllbox .boxTitle span {
  display: inline-block;
  text-align: center;
}

#fortunesheet-search-replace #searchAllbox .boxTitle span:nth-of-type(1) {
  width: 25%;
}

#fortunesheet-search-replace #searchAllbox .boxTitle span:nth-of-type(2) {
  width: 25%;
}

#fortunesheet-search-replace #searchAllbox .boxTitle span:nth-of-type(3) {
  width: 50%;
}

#fortunesheet-search-replace #searchAllbox .boxMain .boxItem {
  height: 30px;
  line-height: 29px;
  border-bottom: 1px solid #d4d4d4;
  padding: 0 5px;
  box-sizing: border-box;
}

#fortunesheet-search-replace #searchAllbox .boxMain .boxItem.on {
  background-color: #8C89FE;
  color: #fff;
}

#fortunesheet-search-replace #searchAllbox .boxMain .boxItem span {
  display: block;
  text-align: center;
  float: left;
}

#fortunesheet-search-replace #searchAllbox .boxMain .boxItem span:nth-of-type(1) {
  width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#fortunesheet-search-replace #searchAllbox .boxMain .boxItem span:nth-of-type(2) {
  width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#fortunesheet-search-replace #searchAllbox .boxMain .boxItem span:nth-of-type(3) {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fortune-link-modify-modal {
  position: absolute;
  overflow: hidden;
  background-color: #fff;
  z-index: 300;
  padding: 6px 20px 10px 20px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 16%);
  border: solid 0.5px #e5e5e5;
  border-radius: 6px;
}

.fortune-link-modify-modal.link-toolbar {
  display: flex;
  flex-direction: row;
  padding: 2px 8px 2px 16px;
  align-items: center;
}


.fortune-link-modify-modal .link-content {
  margin-right: 6px;
}

.fortune-link-modify-modal .link-content:hover {
  color: #2674fb;
  cursor: pointer;
}

.fortune-link-modify-modal .divider {
  width: 1px;
  height: 16px;
  margin: 0px 6px ;
  background-color: #e0e0e0;
  flex-shrink: 0;
}

.fortune-link-modify-modal .fortune-toolbar-button {
  padding: 6px;
}

.fortune-link-modify-modal .fortune-toolbar-button:hover {
  background-color: rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.fortune-link-modify-modal.range-selection-modal {
  width: 380px;
  padding: 22px;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
  background-color: #fff;
}

.fortune-link-modify-line {
  padding-top: 10px;
}

.fortune-link-modify-title {
  font-size: 12px;
  display: inline-block;
  height: 16px;
  width: 74px;
  line-height: 16px;
  padding: 7px 0;
  color: #333333;
  margin-right: 6px;
}

.fortune-link-modify-input,
.fortune-link-modify-select {
  width: 232px;
  box-sizing: border-box;
  height: 26px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  padding: 1px 8px;
  outline: none;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.fortune-link-modify-input:focus,
.fortune-link-modify-modal .range-selection-input:focus {
  border-color: #4d90fe;
}

.fortune-link-modify-input.error-input,
.fortune-link-modify-modal .range-selection-input.error-input {
  border: 1px solid #EF4E2F !important;
}

.fortune-link-modify-cell-selector {
  width: 20px;
  right: 24px;
  padding: 4px;
  position: absolute;
  display: inline-block;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.fortune-link-modify-modal .modal-title {
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, .88);
  margin-bottom: 12px;
  line-height: 24px;
}

.fortune-link-modify-modal .range-selection-input {
  display: block;
  outline: none;
  font-size: 14px;
  height: 32px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 7px 11px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.fortune-link-modify-modal .modal-icon-close {
  position: absolute;
  right: 22px;
  top: 22px;
  cursor: pointer;
}

.fortune-link-modify-modal .validation-input-tip {
  height: 17px;
  font-size: 12px;
  color: #EF4E2F;
  margin: 3px 0px ;
}

.fortune-link-modify-modal .button-group {
  display: flex;
}

.fortune-link-modify-modal .modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px 5px 0px;
}

.fortune-link-modify-modal.range-selection-modal .modal-footer {
  padding: 0px;
}

.fortune-link-modify-modal .button-basic {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 32px;
  width: 88px;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
}

.fortune-link-modify-modal .button-default {
  color: rgb(38, 42, 51);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(235, 235, 235);
}

.fortune-link-modify-modal .button-primary {
  color: white;
  background-color: #0188FB;
  margin-left: 14px;
}
#fortunesheet-data-verification {
  min-width: 500px;
  padding: 10px 0px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#fortunesheet-data-verification .title {
  font-size: 16px;
}

#fortunesheet-data-verification .box {
  font-size: 14px;
}

#fortunesheet-data-verification .box .box-item {
  padding: 10px;
  border-bottom: 1px solid #E1E4E8;
}

#fortunesheet-data-verification .box .box-item .box-item-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

#fortunesheet-data-verification .box .box-item .data-verification-range {
  width: 100%;
  height: 30px;
  border: 1px solid #d4d4d4;
}

#fortunesheet-data-verification .box .box-item .show-box-item {
  margin-top: 6px;
  font-size: 12px;
}

#fortunesheet-data-verification .box .box-item .show-box-item .check-box {
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;

}

#fortunesheet-data-verification .box .box-item .show-box-item .check-box input {
  height: 30px;
  padding: 0 10px;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
}

#fortunesheet-data-verification .input-box input {
  height: 30px;
  padding: 4px 10px 4px 10px;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  margin-top: 6px;
}

#fortunesheet-data-verification .input-box span {
  margin: 0px 16px;
}

.data-verification-range .formulaInputFocus {
  width: calc(100% - 30px);
  height: 30px;
  padding: 0 10px px;
  float: left;
  border: none;
  outline-style: none;
  box-sizing: border-box;
}

.data-verification-range .icon {
  float: right;
  margin-top: 4px;
  margin-right: 5px;
  cursor: pointer;
}

#fortunesheet-data-verification .box .box-item .data-verification-type-select {
  width: 100%;
  height: 30px;
  border-color: #d4d4d4;
  outline-style: none;
}

#fortunesheet-data-verification .box .box-item .check {
  font-size: 12px;
  line-height: 24px;
}

#fortunesheet-data-verification .box .box-item .check input {
  vertical-align: text-top;
}

#fortunesheet-data-verification .button-basic {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 10px;
}

#fortunesheet-data-verification .button-primary {
  background: #0188fb;
  border: 1px solid #0188fb;
  color: #fff;
  margin-right: 10px;
}

#fortunesheet-data-verification .button-close {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-right: 10px;
}


#range-dialog {
  box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  background: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .333);
  outline: 0;
  position: absolute;
  color: #000;
  padding: 30px 42px;
  z-index: 100003;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -90%);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#range-dialog .dialog-title {
  background-color: #fff;
  color: #000;
  cursor: default;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  margin: 0 0 16px;
}

#range-dialog input {
  height: 30px;
  padding: 0 10px;
  border: 1px solid #d4d4d4;
  outline-style: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#range-dialog .button-primary {
  background: #0188fb;
  border: 1px solid #0188fb;
  color: #fff;
  margin-right: 10px;
}

#range-dialog .button-close {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-right: 10px;
}

#luckysheet-dataVerification-dropdown-List {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 10000;
  box-sizing: border-box;
  font-size: 12px;
}

#luckysheet-dataVerification-dropdown-List .dropdown-List-item {
  padding: 5px 10px;
  box-sizing: border-box;
  cursor: pointer;
}

#luckysheet-dataVerification-dropdown-List .dropdown-List-item:hover {
  background-color: #E1E1E1;
}
.condition-format-sub-menu {
  position: absolute;
  top: -8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  cursor: default;
  font-size: 12px;
  z-index: 1004;
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
}

.condition-format-item {
  display: flex;
  justify-content: space-between;
  padding: 6px 18px;
  z-index: 1005;
}

.condition-format-item:hover {
  background: #efefef;
}

.condition-format-item span {
  font-size: 10px;
  color: #afafaf;
}

.horizontal-line {
  border-top: 1px solid #ebebeb;
  margin-top: 6px;
  margin-bottom: 6px;
}



.condition-rules .button-basic {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 10px;
}

.condition-rules .button-primary {
  background: #0188fb;
  border: 1px solid #0188fb;
  color: #fff;
  margin-right: 10px;
}

.condition-rules .button-close {
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
}

.condition-rules {
  padding: 0px 42px 34px 42px;
  font-size: 12px;
}

.condition-rules-title {
  color: #000;
  cursor: default;
  font-size: 16px;
  margin-bottom: 18px;
}

.conditin-rules-value {
  margin: 5px 0;
  font-weight: 600;
}

.condition-rules-inpbox {
  width: 198px;
  height: 28px;
  border: 1px solid #d4d4d4;
}

.condition-rules-input {
  width: 150px;
  height: 28px;
  padding: 0 10px;
  border: none;
  outline-style: none;
  float: left;
}

.condition-relues-inputicon {
  float: right;
  margin-top: 2px;
  margin-right: 5px;
  cursor: pointer;
}

.condition-rules-set-title {
  margin: 6px 0px;
}

.condition-rules-setbox {
  border: 1px solid #d4d4d4;
}

.condition-rules-set {
  padding: 5px 10px;
}

.condition-rules-color {
  height: 30px;
  line-height: 30px;
  position: relative;
}

.condition-rules-check {
  float: left;
  margin-top: 10px;
}

.condition-rules-label {
  display: inline-block;
  width: 80px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.condition-rules-select-color {
  padding: 2px;
  border: solid 1px #E5E5E5;
  background: #F5F5F5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(20%, -50%);
}

.condition-rules-between-box {
  display: flex;
  align-items: center;
}

.condition-rules-between-inpbox {
  width: 108px;
  height: 28px;
  border: 1px solid #d4d4d4;
}

.condition-rules-between-input {
  width: 60px;
  height: 28px;
  padding: 0 10px;
  border: none;
  outline-style: none;
  float: left;
}

.condition-rules-date {
  width: 98%;
  border: none;
  line-height: 26px;
}

.condition-rules-select {
  width: 150px;
  height: 30px;
}

.condition-rules-project-box {
  display: flex;
  align-items: center;
}

.condition-rules-project-input {
  margin: 0px 6px;
}
.fortune-toolbar {
  display: flex;
  flex-direction: row;
  background: #fafafc;
  position: relative;
  padding: 5px 0px 3px 15px;
  border-bottom: 1px solid #d4d4d4;
  white-space: nowrap;
  align-items: center;
}

.fortune-toolbar-divider {
  width: 1px;
  height: 20px;
  margin: 0 6px;
  background-color: #e0e0e0;
  flex-shrink: 0;
}

.fortune-toolbar-menu-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.fortune-toolbar-menu-divider {
  width: "100%";
  height: 1px;
  margin: 2px 6px;
  background-color: #e0e0e0;
}

.fortune-toolbar-button,
.fortune-toolbar-combo {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: 0;
  outline: none;
  padding: 0;
  list-style: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 2px;
  margin: 2px 4px;
}

.fortune-toolbar-combo-button,
.fortune-toolbar-combo-arrow {
  display: flex;
  align-items: center;
}

.fortune-toolbar-button:hover,
.fortune-toolbar-combo:hover {
  background-color: rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.fortune-toolbar-combo-arrow:hover {
  background-color: rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.fortune-toolbar-button:active,
.fortune-toolbar-combo:active {
  background-color: rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.fortune-toobar-combo-container {
  position: relative;
}

.fortune-toolbar-combo-popup {
  position: absolute;
  white-space: nowrap;
  top: 32px;
  left: 0;
  z-index: 1004;
}

.fortune-toolbar-select::-webkit-scrollbar {
  display: none ;
}

.fortune-toolbar-select,
.fortune-toolbar-color-picker {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 6px;
  background: white;
}

.fortune-toolbar-select {
  padding-left: 0;
  padding-right: 0;
  overflow: auto;
  max-height: 75vh;
}

.fortune-toolbar-combo-button {
  font-size: 12px;
}

.fortune-toolbar-select-option {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  min-width: 60px;
  padding: 8px 12px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.fortune-toolbar-select-option:hover {
  background: #efefef;
}

.fortune-toolbar-select::-webkit-scrollbar {
  display: none;
}

.fortune-toolbar-color-picker-row {
  display: flex;
  flex-direction: row;
}

.fortune-toolbar-combo-text {
  margin: 0 4px;
}

.fortune-toolbar-color-picker-item {
  width: 16px;
  height: 16px;
  margin: 1px;
  cursor: pointer;
}

.fortune-tooltip {
  visibility: hidden;
  background-color: #666;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 6px;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  top: 40px;
  white-space: nowrap;
}

.fortune-toolbar-button:hover .fortune-tooltip,
.fortune-toolbar-combo:hover .fortune-tooltip {
  visibility: visible;
}

.fortune-toolbar-more-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  margin-right: 40px;
  top: 40px;
  max-width: 348px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background: white;
  flex-wrap: wrap;
  z-index: 1002;
}

.fortune-toolbar-subtext {
  -webkit-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -o-transition: all 0.218s;
  transition: all 0.218s;
  font-size: 12px;
  left: auto;
  padding-top: 1px;
  padding-left: 24px;
  text-align: right;
  opacity: .5;
  filter: alpha(opacity=50);
  color: #000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: Arial;
  line-height: 100%;
}

.toolbar-item-sub-menu {
  position: absolute;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  cursor: default;
  font-size: 12px;
  z-index: 1004;
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
  border-radius: 6px;
}

.set-background-item {
  display: flex;
  justify-content: center;
  padding: 6px 18px;
  z-index: 1005;
}

.set-background-item:hover {
  background: #efefef;
}

#fortunesheet-custom-color {
  min-width: 164px;
  background: rgb(240, 240, 240);
  border-radius: 6px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, .2);
  font-size: 12px;
}

#fortunesheet-custom-color .color-reset {
  position: relative;
  color: #333;
  cursor: pointer;
  list-style: none;
  padding: 10px;
  white-space: nowrap;
  padding-left: 8px;
  vertical-align: middle;
  padding-right: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 6px;
  background: white;
}

#fortunesheet-custom-color .color-reset:hover {
  background: rgb(230, 230, 230);
}

#fortunesheet-custom-color .custom-color {
  position: relative;
  margin: auto;
  padding: 10px;
  border-radius: 6px;
  background: white;
  display: flex;
  align-items: center;
  margin: 1px 0px;
  display: flex;
  justify-content: space-around;
}

.button-basic {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.button-primary {
  background: #0188fb;
  border: 1px solid #0188fb;
  color: #fff;
  margin-right: -4px;
}

.fortune-border-select-menu{
    position: absolute;
    bottom: 0px;
}

.fortune-border-color-preview {
  height: 3px;
}

.fortune-border-select-option {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  min-width: 60px;
  padding: 8px 12px;
}

.fortune-border-select-option:hover {
  background: #efefef;
  cursor: pointer;
}

.fortune-border-style-preview{
  height: 3px;
  overflow: hidden;
}

.fortune-border-style-picker-menu{
  padding: 0px 10px;
}

.fortune-border-style-picker-menu:hover{
  background: #efefef;
  cursor: pointer;
}

/*函数公式查找样式*/
#luckysheet-search-formula {
  font-size: 12px;
}

#luckysheet-search-formula .inpbox {
  margin-bottom: 5px;
}

#luckysheet-search-formula .inpbox div {
  display: block;
  margin-bottom: 5px;
}

#luckysheet-search-formula .inpbox input {
  width: 100%;
  height: 24px;
  line-height: 24px;
  border: 1px solid #d4d4d4;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 12px;
}

#luckysheet-search-formula .selbox {
  margin-bottom: 5px;
}

#luckysheet-search-formula .selbox select {
  width: 50%;
  height: 24px;
  line-height: 24px;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  font-size: 12px;
}

#luckysheet-search-formula .listbox label {
  display: block;
  margin-bottom: 5px;
}

#formulaTypeList {
  width: 300px;
  height: 170px;
  border: 1px solid #d4d4d4;
  overflow-y: scroll;
}

.formulaList {
  width: 300px;
  height: 170px;
  border: 1px solid #d4d4d4;
  overflow-y: scroll;
}

.listBox {
  padding: 5px;
  border-bottom: 1px solid #d4d4d4;
}

.listBox.on {
  background-color: #8C89FE;
  color: #fff;
}

#fortunesheet-split-column {
    /* position: absolute;
    padding: 30px 42px;
    z-index: 1002; */
    min-width: 500px;
}
#fortunesheet-split-column label {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none
}
#fortunesheet-split-column .title {
    font-size: 16px;
}
#fortunesheet-split-column .splitDelimiters {
    margin-top: 10px;
}
#fortunesheet-split-column .splitSymbols {
    position: relative;
    border: 1px solid #dfdfdf;
    padding: 5px;
    margin: 5px 0px;
}
#fortunesheet-split-column .splitSymbol {
    font-size: 14px;
}
#fortunesheet-split-column .splitSimple {
    position: absolute;
    top: 114px;
    left: 0px;
}
#fortunesheet-split-column #otherValue {
    margin-left: 5px;
    width: 50px;
    padding: 0 5px;
}
#fortunesheet-split-column .splitDataPreview {
    font-size: 14px;
    margin-top: 26px;
}
#fortunesheet-split-column .splitColumnData {
    border: 1px solid #dfdfdf;
    padding: 5px;
    margin: 5px 0px;
    height: 100px;
    overflow-y: scroll;
}
#fortunesheet-split-column .button-basic {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
#fortunesheet-split-column .button-primary {
    background: #0188fb;
    border: 1px solid #0188fb;
    color: #fff;
    margin-right: 10px;
}
#fortunesheet-split-column .button-close {
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
}
#fortunesheet-split-column table {
    border-collapse: collapse;
}
#fortunesheet-split-column tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
#fortunesheet-split-column td {
    border: 1px solid #333;
    display: table-cell;
    vertical-align: inherit;
}
label {
    cursor: default;
}
#fortunesheet-location-condition {
    min-width: 500px;
}
#fortunesheet-location-condition .title {
    background-color: #fff;
    color: #000;
    cursor: default;
    font-size: 16px;
    font-weight: normal;
    line-height: 48px;
}
#fortunesheet-location-condition .listbox {
    border: 1px solid #dfdfdf;
    padding: 10px;
    font-size: 14px;
    color: #000;
}
#fortunesheet-location-condition .listbox .listItem {
    padding: 5px 0;
}
#fortunesheet-location-condition .listbox .listItem input[type="radio"] {
    float: left;
    margin-top: 5px;
}
#fortunesheet-location-condition .listItem {
    padding: 5px 0;
}
#fortunesheet-location-condition .listItem .subItem {
    height: 30px;
    padding: 0 10px;
    display: block;
}
#fortunesheet-location-condition input[type="radio"] {
    float: left;
    margin-top: 3px;
}
#fortunesheet-location-condition .listbox .listItem .subbox {
    height: 30px;
    padding: 0 10px;
}
#fortunesheet-location-condition .listbox .listItem .subbox .subItem {
    float: left;
    margin-right: 5px;
}
#fortunesheet-location-condition .button-basic {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 2px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: 10px;
}
#fortunesheet-location-condition .button-primary {
    background: #0188fb;
    border: 1px solid #0188fb;
    color: #fff;
    margin-right: 10px;
}
#fortunesheet-location-condition .button-close {
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
}
.listBox {
  display: flex;
  justify-content: space-between;
}

.inpbox {
  margin-bottom: 10px;
}

.decimal-places-input {
  width: 70px;
}


.format-list {
  width: 300px;
  height: 170px;
  border: 1px solid #d4d4d4;
  overflow-y: scroll;
}



.fortune-fx-editor {
  display: flex;
  flex-direction: row;
  height: 28px;
  border-bottom: 1px solid #d4d4d4;
  background-color: white;
}

.fortune-fx-icon {
  display: flex;
  align-items: center;
  margin: 0 12px;
}

.fortune-name-box-container {
  width: 99px;
  border-right: 1px solid #d4d4d4;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.fortune-name-box {
  width: 100%;
  text-align: center;
  margin: 0;
  outline: none;
  cursor: text;
  /* -webkit-user-modify: read-write-plaintext-only; */
  white-space: nowrap;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  background-color: white;
  word-wrap: break-word;
  -webkit-nbsp-mode: space;
  -webkit-line-break: after-white-space;
}

.fortune-fx-input-container {
  padding-left: 10px;
  overflow: visible;
  padding: 0;
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  border-left: 1px solid #e5e5e5;
}

.fortune-fx-input {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  padding-left: 2px;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
  outline: none;
  cursor: text;
  white-space: pre-wrap;
  word-wrap: break-word;
  -webkit-transform: translateZ(0);
  -webkit-nbsp-mode: space;
  -webkit-line-break: after-white-space;
  background-color: white;
  padding-top: 7px;
  box-sizing: border-box;
  color:black;
  text-align: left;
}
.fortune-fx-input[contenteditable="true"] {
  -webkit-user-modify: read-write-plaintext-only;
}
.luckysheet-sheet-area {
  width: 100%;
  box-sizing: border-box;
  background-color: #fafafc;
  color: #444;
  height: 31px;
  padding: 0 0 0 44px;
  margin: 0;
  -webkit-touch-callout: none;
  cursor: default;
  transition: 0.3s ease all;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

#luckysheet-sheet-content {
  width: 0;
  flex: 3;
  display: flex;
  align-items: center;
}

#luckysheet-bottom-pager {
  width: 0;
  background-color: #fafafc;
  z-index: 1;
  flex: 2;
  text-align: right;
  white-space: nowrap;
}

.luckysheet-sheet-area>div,
.luckysheet-sheet-area .luckysheet-sheets-item {
  display: inline-block;
  /* margin-right: 6px;
	margin-top: 1px;
	padding: 1px 6px; */
  /* padding: 6px 10px; */
}

.fortune-sheettab-container {
  padding: 0px 0px;
  margin-left: 0px;
  position: relative;
  max-width: 54%;
  vertical-align: bottom;
  display: inline-block;
}

.fortune-sheettab-container .boundary {
  position: absolute;
  top: 0;
  width: 6px;
  height: 100%;
  z-index: 1;
  background: rgb(255, 255, 255);
}

.fortune-sheettab-container .boundary-left {
  left: 0;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #4445;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8880);
}

.fortune-sheettab-container .boundary-right {
  right: 0;
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
  --tw-gradient-from: #4445;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, #8880);
}

.fortune-sheettab-container .fortune-sheettab-container-c {
  padding: 0px 0px;
  margin-left: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  max-width: 100%;
  vertical-align: bottom;
  display: inline-block;
}

.luckysheet-sheet-container-menu-hide .luckysheet-sheets-item {
  padding-right: 5px !important;
}

.luckysheet-sheet-container-menu-hide .luckysheet-sheets-item-menu {
  display: none !important;
}

.luckysheet-sheet-area div.luckysheet-sheets-item {
  padding: 2px 6px;
  height: 29px;
  line-height: 29px;
  background-color: #fafafc;
  color: #676464;
  min-width: 30px;
  top: 0px;
  position: relative;
  margin-right: -1px;
  cursor: pointer;
  transition: all 0.1s;
  font-size: 13px;
  padding: 2px 19px 0px 5px;
  box-sizing: border-box;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  vertical-align: middle;
}

.luckysheet-sheet-area div.luckysheet-sheets-item:last-child {
  margin-right: 1px;
}

.luckysheet-sheet-area div.luckysheet-sheets-item:hover {
  background-color: #efefef;
  /* border-color: #a5a5a5; */
  color: #490500;
}

.luckysheet-sheet-area div.luckysheet-sheets-item .luckysheet-sheets-item-menu {
  margin-left: 2px;
  display: inline-block;
  top: -2px;
  position: relative;
  color: #a1a1a1;
  position: absolute;
  height: 100%;
  width: 15px;
  right: 0px;
  text-align: center;
}

.luckysheet-sheet-area div.luckysheet-sheets-item .luckysheet-sheets-item-menu:hover {
  color: #2a2a2a;
  cursor: pointer;
}

.luckysheet-sheet-area div.luckysheet-sheets-item .luckysheet-sheets-item-name {
  padding: 0px 3px;
}

.luckysheet-sheets-item-color {
  width: 100%;
  height: 10%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.luckysheet-sheet-area div.luckysheet-sheets-item .luckysheet-sheets-item-name[contenteditable="true"] {
  border: 1px solid #d9d9d9;
  display: inline-block;
  height: 18px;
  line-height: 18px;
  min-width: 8px;
  margin: -4px -1px;
  -moz-user-modify: read-write-plaintext-only;
  -webkit-user-modify: read-write-plaintext-only;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -webkit-user-select: text !important;
}

.luckysheet-sheet-area div.luckysheet-sheets-item .luckysheet-sheets-item-name[contenteditable="true"]:focus {
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid #4d90fe;
  outline: none;
}

.luckysheet-sheet-area div.luckysheet-sheets-item-active {
  /* padding: 2px 8px; */
  height: 29px;
  line-height: 29px;
  background-color: #efefef;
  /* border-color: #aaa; */
  border-top-color: #fff;
  color: #222;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); */
  cursor: default;
  /* top: -2px; */
  /* border-bottom: none; */
  /* padding-right: 20px; */
}

.luckysheet-sheet-area div.luckysheet-sheets-item-active:hover {
  background-color: #ececec;
  /* border-color: #aaa; */
  color: #222;
}

.fortune-sheettab-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  width: 29px;
}

.fortune-sheettab-button:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  width: 29px;
  background-color: #efefef;
}

.luckysheet-noselected-text {
  -moz-user-select: -moz-test;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fortune-sheettab-scroll {
  display: flex;
  align-items: center;
  padding: 0 5px;
  height: 29px;
  cursor: pointer;
}

.fortune-sheettab-scroll:hover {
  background-color: #e0e0e0;
}

.fortune-sheettab-placeholder {
  display: inline-block;
  width: 30px;
  height: 29px;
  line-height: 29px;
  vertical-align: middle;
}

.sheet-list-container {
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.luckysheet-sheet-selection-calInfo {
  display: flex;
  font-size: 12px;
  align-content: center;
  padding: 0 0 0 44px;
  height: 22px;
  align-self: flex-end;
}

.luckysheet-sheet-selection-calInfo div {
  margin: auto 0;
  white-space: nowrap;
  margin-right: 7px;
}

.luckysheet-sheets-item-function {
  width: 12px;
  height: 24px;
  position: absolute;
  top: 4px;
  right: 2px;
}
.fortune-context-menu {
  max-height: 100%;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
  transition: opacity 0.218s;
  background: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: default;
  font-size: 13px;
  margin: 0;
  outline: none;
  padding: 6px 0;
  position: absolute;
  z-index: 1004;
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.fortune-context-menu input.luckysheet-mousedown-cancel {
  width: 35px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

.fortune-context-menu-divider {
  width: "100%";
  height: 1px;
  margin: 4px 0;
  background-color: #e0e0e0;
}

.luckysheet-cols-menu .luckysheet-cols-menuitem {
  position: relative;
  color: #333;
  cursor: pointer;
  list-style: none;
  margin: 0;
  padding: 1px 6em 1px 20px;
  white-space: nowrap;
  padding-left: 8px;
  vertical-align: middle;
  padding-right: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* 右击菜单项目 hover背景色 */
.luckysheet-cols-menu .luckysheet-cols-menuitem:hover,
.luckysheet-cols-menu .luckysheet-cols-menuitem-hover {
  background: #EFEFEF;
}

.luckysheet-cols-menu .luckysheet-cols-menuitem .luckysheet-cols-menuitem-content {
  position: relative;
  color: #333;
  cursor: pointer;
  list-style: none;
  margin: 0;
  padding: 6px 7em 6px 30px;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.fortune-filter-menu .luckysheet-cols-menuitem {
  padding: 0px;
}

.fortune-filter-menu .luckysheet-cols-menuitem .luckysheet-cols-menuitem-content {
  padding: 7px 24px;
}

.fortune-menuitem-row {
  display: flex;
  padding: 7px 24px;
  white-space: pre;
  align-items: center;
}

.fortune-byvalue-btn {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}


.luckysheet-filter-bycolor-submenu .button-basic,
.fortune-filter-menu .button-basic {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.luckysheet-filter-bycolor-submenu .button-basic {
  margin: 5px 20px;
}

.luckysheet-filter-bycolor-submenu .button-default,
.fortune-filter-menu .button-default {
  color: rgb(38, 42, 51);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(235, 235, 235);
  margin-left: 10px;
}

.luckysheet-filter-bycolor-submenu .button-default:hover,
.fortune-filter-menu .button-default:hover {
  background-color: #e6e6e6;
}

.luckysheet-filter-bycolor-submenu .button-primary,
.fortune-filter-menu .button-primary {
  color: white;
  background-color: #0188FB;
}

.luckysheet-filter-bycolor-submenu .button-primary:hover,
.fortune-filter-menu .button-primary:hover {
  background: #5391ff;
}

.fortune-filter-menu .button-danger {
  color: white;
  background-color: #d9534f;
  margin-left: 10px;
}

.fortune-filter-menu .button-danger:hover {
  background-color: #c9302c;
}

.filter-bycolor-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filtermenu-input-container {
  padding: 0px;
}

.filtermenu-input-container input.luckysheet-mousedown-cancel {
  margin: 0px 20px;
  width: 230px;
  box-sizing: border-box;
  height: 26px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  padding: 1px 8px;
  outline: none;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  text-align: start;
}

.filtermenu-input-container input.luckysheet-mousedown-cancel {
  border: 1px solid #A1A1A1;
}

.filtermenu-input-container input.luckysheet-mousedown-cancel:focus {
  border: 1px solid rgb(1, 136, 251);
  outline: none;
}

.byvalue-btn-row {
  justify-content: space-between;
  padding-bottom: 0px;
  align-items: flex-start;
}

.filter-caret {
  width: 0;
  height: 0;
  display: inline-block;
  border: 4px solid transparent;
}

.filter-caret.right {
  margin-left: 2px;
  margin-right: 3px;
  border-left-color: #000000;
}

.filter-caret.down {
  margin-top: 5px;
  margin-right: 5px;
  border-top-color: #000000;
}

.filter-checkbox {
  margin-left: 0px;
  margin-right: 5px;
}

#luckysheet-filter-byvalue-select {
  min-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4px 24px;
}

#luckysheet-filter-byvalue-select .count,
#luckysheet-pivotTableFilter-byvalue-select .count {
  color: gray;
  margin-left: 5px;
}

#luckysheet-filter-byvalue-select .select-item {
  display: flex;
  align-items: center;
}

/*颜色筛选 -- pan*/
.luckysheet-filter-bycolor-submenu {
  position: absolute;
  min-width: 170px;
  font-size: 12px;
  padding: 5px 0;
  z-index: 1004;
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: #ffffff;
}

.luckysheet-filter-bycolor-submenu .title {
  padding: 10px;
  font-weight: 600;
  color: #333;
  background-color: #f4f4f4;
  text-align: center;
}

.luckysheet-filter-bycolor-submenu .one-color-tip {
  padding: 7px 30px;
  text-align: center;
}

.luckysheet-filter-bycolor-submenu .color-list {
  max-height: 128px;
  overflow: auto;
}

.luckysheet-filter-bycolor-submenu .item {
  padding: 5px 40px 5px 20px;
  cursor: pointer;
  position: relative;
  background-color: #ffffff;
}

.luckysheet-filter-bycolor-submenu .item:hover {
  background-color: #d3d3d3;
}

.luckysheet-filter-bycolor-submenu .item .color-label {
  display: block;
  width: 70px;
  height: 20px;
  border: 1px solid #d1d1d1;
}

.luckysheet-filter-bycolor-submenu .item input[type="checkbox"] {
  position: absolute;
  right: 10px;
  top: 6px;
}

.change-color-triangle {
  position: absolute;
  top: 3px;
  right: -18px;
}
#fortunesheet-change-color {
  min-width: 164px;
  height: 252px;
  background: rgb(240, 240, 240);
  position: absolute;
  bottom: -110px;
  left: 197px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, .2);
}

#fortunesheet-change-color .color-reset {
  position: relative;
  color: #333;
  cursor: pointer;
  list-style: none;
  padding: 10px;
  white-space: nowrap;
  padding-left: 8px;
  vertical-align: middle;
  padding-right: 24px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 6px;
  background: white;
}

#fortunesheet-change-color .color-reset:hover {
  background: rgb(230, 230, 230);
}

#fortunesheet-change-color .custom-color {
  position: relative;
  margin: auto;
  padding: 10px;
  border-radius: 6px;
  background: white;
  display: flex;
  align-items: center;
  margin: 1px 0px;
  display: flex;
  justify-content: space-around;
}

.button-basic {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.button-primary {
  background: #0188fb;
  border: 1px solid #0188fb;
  color: #fff;
  margin-right: -4px;
}
.fortune-sheet-list {
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 120px;
  position: absolute;
  z-index: 10002;
  bottom: 31px;
  margin-left: 72px;
  max-height: 60%;
}

.fortune-sheet-list-item {
  height: 30px;
  line-height: 30px;
  width: 100%;
  margin-right: 46px;
  cursor: pointer;
}

.fortune-sheet-list-item-name {
  margin-right: 15px;
  position: relative;
}

.fortune-sheet-list-item-name .luckysheet-sheets-list-item-color {
  width: 6%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: -6px;
}

.fortune-sheet-list :hover {
  background-color: #EFEFEF;
}

.fortune-sheet-hidden-button {
  margin-right: 15px;
  display: inline-flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
}

.fortune-sheet-hidden-button :hover {
  background-color: #D0D0D0;
}

.fortune-sheet-selected-check-sapce {
  width: 20px;
  display: inline-block;
  margin-left: 15px;
}