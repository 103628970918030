.rpn-notification-holder {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 100000;
}

.rpn-notification-holder.top-left {
    left: 10px;
}

.rpn-notification-holder.top-middle {
    left: 50%;
    transform: translateX(-50%);
}

.rpn-notification-holder.top-right {
    right: 30px;
}

.rpn-notification-holder.bottom-left {
    left: 10px;
    bottom: 10px;
}

.rpn-notification-holder.bottom-middle {
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
}

.rpn-notification-holder.bottom-right {
    right: 30px;
    bottom: 10px;
}

.rpn-notification-card {
    display: flex;
    flex-direction: column;
    width: 250px;
    margin-top: 15px;
    -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.2s; /* Firefox < 16 */
    -ms-animation: fadein 0.2s; /* Internet Explorer */
    -o-animation: fadein 0.2s; /* Opera < 12.1 */
    animation: fadein 0.2s;
}

.rpn-notification-card-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    font-weight: bold;
    font-size: 11px;
    background-color: rgb(51, 51, 51);
    color: white;
    border-radius: 5px 5px 0 0;

}

.rpn-notification-card-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 10px 15px 10px;
    flex-wrap: wrap;
    background-color: rgb(59, 59, 59);
    color: white;
    border-radius: 0 0 5px 5px;
    justify-content: center;
    box-shadow: 0 5px 0px 0px rgb(0, 0, 0);
}

.rpn-notification-card-bottom span {
    padding: 3px 0;
}

.rpn-notification-card-bottom .subtitle {
    font-weight: bold;
    font-size: 12px;
}

.rpn-notification-card-bottom .message {
    font-size: 14px;
}

.rpn-notification-card-close {
    font-size: 10px;
    cursor: pointer;
    height: 100%;
}

.rpn-notification-card-top.light {
    background-color: rgb(240, 240, 240);
    color: black;
}

.rpn-notification-card-bottom.light {
    background-color: rgb(245, 245, 245);
    color: black;
    box-shadow: 0 5px 0px 0px rgb(218, 217, 217);
}

.rpn-notification-card-top.darkblue {
    background-color: #2d3542;
    color: white;
}

.rpn-notification-card-bottom.darkblue {
    background-color: #394357;
    color: white;
    box-shadow: 0 5px 0px 0px #1f2b42;

}

.rpn-notification-card-top.red {
    background-color: rgb(187, 37, 37);
    color: white;
}

.rpn-notification-card-bottom.red {
    background-color: #D32F2F;
    color: white;
    box-shadow: 0 5px 0px 0px rgb(145, 1, 1);
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}